import React from 'react';
import cover from './topcon/signin-cover-cutout.jpg';
import { ReactComponent as AngleLeft } from './shapes/angles/angle-left.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Logo } from './logo.svg';

function SignInCover() {
  const intl = useIntl();
  const history = useHistory();

  function handleClick() {
    history.push('/tool');
  }

  return (
    <section>
      <div className="container d-flex flex-column">
        <div className="row align-items-center justify-content-center gx-0 min-vh-100">
          <div className="col-12 col-md-6 col-lg-4 py-8 py-md-11">
            <div className="mb-4">
              <Logo />
            </div>
            <h1 className="mb-0 font-weight-bold">
              <FormattedMessage
                id="signIn.title"
                defaultMessage="Sign in"
                description="Sign in Title"
              />
            </h1>
            <p className="mb-6 text-muted">
              <FormattedMessage id="signIn.subtitle" defaultMessage="Demo for internal purposes" />
            </p>
            <form className="mb-6" autoComplete="off">
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  <FormattedMessage id="emailAddress" defaultMessage="Email Address" />
                </label>
                <input
                  type="em"
                  className="form-control"
                  id="email"
                  placeholder="user@topcon.com"
                />
              </div>
              <div className="form-group mb-5">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <input
                  type="pw"
                  className="form-control"
                  id="password"
                  placeholder={intl.formatMessage({
                    id: 'passwordInput',
                    defaultMessage: 'Enter your password',
                  })}
                />
              </div>
              <button className="btn btn-block btn-primary" type="button" onClick={handleClick}>
                Sign in
              </button>
            </form>
            <p className="mb-0 font-size-sm text-muted">
              Don't have an account yet? <a href="signup-cover.html">Sign up</a>.
            </p>
          </div>
          <div className="col-lg-7 offset-lg-1 align-self-stretch d-none d-lg-block">
            <div
              className="h-100 w-cover bg-cover"
              style={{
                backgroundImage: `url(${cover})`,
              }}
            ></div>
            <div className="shape shape-left shape-fluid-y svg-shim text-white">
              <AngleLeft />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignInCover;
