import React from 'react';
import './App.css';

import Header from './components/Header';
import Subheader from './components/Subheader';

function Tool() {
  return (
    <>
      <Header />
      <Subheader />
      <main className="pb-8 pb-md-11 mt-md-n6">
        <div className="container-md">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
                <div className="collapse d-md-block" id="sidenavCollapse">
                  <div className="card-body">
                    <h6 className="font-weight-bold text-uppercase mb-3">Products</h6>

                    <ul className="card-list list text-gray-700 mb-6 ">
                      <li className="list-item list-link text-reset d-block">
                        3D-MC2<div className="float-right">€ 4.000,00</div>
                      </li>
                      <li className="list-item list-link text-reset d-block">
                        Hiper HR<div className="float-right">€ 6.000,00</div>
                      </li>
                      <li className="list-item list-link text-reset d-block">
                        FC-500<div className="float-right">€ 2.000,00</div>
                      </li>
                    </ul>

                    <h6 className="font-weight-bold text-uppercase mb-3">Total</h6>

                    <ul className="card-list list text-gray-700 mb-0">
                      <li className="list-item list-link text-reset d-block">
                        Discount<div className="float-right">- € 1.000,00</div>
                      </li>
                      <li className="list-item list-link text-reset font-weight-bold d-block">
                        Grand Total<div className="float-right">€ 11.000,00</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="card card-bleed shadow-light-lg mb-6">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="mb-0">
                        Selected for <span className="font-weight-bold">Komatsu D61PX-15</span>
                      </h4>
                    </div>
                    <div className="col-auto">
                      <a className="small text-gray-700" href="#!">
                        Edit make and model
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col ml-n5">
                          <p className="mb-0">3D-MC2</p>

                          <small className="text-gray-700">
                            GNSS Antenna, GX-55 Control Box, IMU Sensor
                          </small>
                        </div>
                        <div className="col-auto mr-n5">
                          <span className="badge badge-pill badge-secondary-soft">
                            <span className="h6 text-uppercase font-weight-bold">€ 4.000,00</span>
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="dropdown">
                            <a
                              className="text-gray-400 text-decoration-none"
                              data-toggle="dropdown"
                              href="#!"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical"></i>
                            </a>
                            <div className="dropdown-menu">
                              <a className="dropdown-item" href="#!">
                                Action
                              </a>
                              <a className="dropdown-item" href="#!">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#!">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col ml-n5">
                          <p className="mb-0">Hiper HR</p>

                          <small className="text-gray-700">Multi purpose GNSS Receiver</small>
                        </div>
                        <div className="col-auto mr-n5">
                          <span className="badge badge-pill badge-secondary-soft">
                            <span className="h6 text-uppercase font-weight-bold">€ 6.000,00</span>
                          </span>
                        </div>
                        <div className="col-auto">
                          <div className="dropdown">
                            <a
                              className="text-gray-400 text-decoration-none"
                              data-toggle="dropdown"
                              href="#!"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical"></i>
                            </a>
                            <div className="dropdown-menu">
                              <a className="dropdown-item" href="#!">
                                Action
                              </a>
                              <a className="dropdown-item" href="#!">
                                Another action
                              </a>
                              <a className="dropdown-item" href="#!">
                                Something else here
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mt-6">
                    <button className="btn btn-xs btn-outline-white">
                      <i className="fe fe-plus"></i> Add product
                    </button>
                  </div>
                </div>
              </div>

              <div className="card card-bleed shadow-light-lg mb-6">
                <div className="card-header">
                  <h4 className="mb-0">Software</h4>
                </div>
                <div className="card-body">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <div className="row align-items-center">
                        <div className="col ml-n5">
                          <p className="mb-0">No software yet</p>

                          <small className="text-gray-700">Add software modules or support</small>
                        </div>
                        <div className="col-12 col-md-auto">
                          <button className="btn btn-xs btn-block btn-primary mt-5 mt-md-0">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-bleed shadow-light-lg mb-6">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h4 className="mb-0">Quote summary</h4>
                    </div>
                    <div className="col-auto">
                      <a className="small text-gray-700" href="#!">
                        Calculation breakdown
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="currentPassword">Discount</label>
                    <input className="form-control" id="currentPassword" type="password" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="newPassword">Total</label>
                    <input className="form-control" id="newPassword" type="password" />
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirmPassword">E-mail</label>
                    <input className="form-control" id="confirmPassword" type="password" />
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-auto">
                      <button className="btn btn-block btn-primary" type="submit">
                        Save quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Tool;
