import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import SignInCover from './SignInCover';
import Tool from './Tool';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import Amplify from "aws-amplify";
// import { AmplifyAuthenticator, AmplifySignUp } from "@aws-amplify/ui-react";
// import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import './scss/theme.scss';

import locale_en from './translations/en.json';
import locale_nl from './translations/nl.json';
// import SignIn from "./components/auth/SignIn";

// Amplify.configure({
//   region: 'eu-west-1',
//   userPoolId: 'eu-west-1_2yUIt6N0r',
//   userPoolWebClientId: '7vt07gdcj377fp6md0h0mj1fqp',
// });

const data: any = {
  nl: locale_nl,
  en: locale_en,
};

// const language = navigator.language.split(/[-_]/)[0];

const AuthStateApp: React.FunctionComponent = () => {
  // const [authState, setAuthState] = React.useState<AuthState>();
  // const [user, setUser] = React.useState<object | undefined>();

  // React.useEffect(() => {
  //   return onAuthUIStateChange((nextAuthState, authData) => {
  //     setAuthState(nextAuthState);
  //     setUser(authData);
  //   });
  // }, []);

  // return authState === AuthState.SignedIn && user ? (
  return (
    <IntlProvider locale={'en'} messages={data['en']}>
      <Router>
        <Switch>
          <Route exact path="/">
            <SignInCover />
          </Route>
          <Route path="/tool">
            <Tool />
          </Route>
        </Switch>
      </Router>
    </IntlProvider>
  );
  // ) : (
  //   <AmplifyAuthenticator>
  //     <AmplifySignUp slot="fuckup" />
  //     {/* <SignIn slot="sign-in" /> */}
  //   </AmplifyAuthenticator>
  // );
};

ReactDOM.render(
  <React.StrictMode>
    <AuthStateApp />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
