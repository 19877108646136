import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../logo.svg';

function Header() {
  return (
    <div className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container-md">
        <nav className="navbar-brand">
          <div>
            <Link to="/tool" className="navbar-brand">
              <img src={logo} className="navbar-brand-img" alt="Topcon" />
            </Link>
          </div>
        </nav>
        <div className="collapse navbar-collapse">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fe fe-x" />
          </button>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle active" href="#">
                Quotes
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#">
                Products
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#">
                Leads
              </a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#">
                Settings
              </a>
            </li>
          </ul>
          <Link to="/" className="navbar-btn btn btn-light btn-sm lift ml-auto" href="#">
            Account
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
