import React from 'react';

function Subheader() {
  return (
    <header
      className="pt-9 pb-11 d-none d-md-block"
      style={{ backgroundColor: '#222', color: 'red' }}
    >
      <div className="container-md">
        <div className="row align-items-center">
          <div className="col">
            <h1 className="font-weight-bold text-white mb-2">
              New quote{' '}
              <span className="font-size-sm">
                <i
                  className="fe fe-edit text-gray-500"
                  data-toggle="tooltip"
                  title=""
                  data-original-title="We use the the phone number you provide in General"
                ></i>
              </span>
            </h1>
            <p className="font-size-lg text-white-75 mb-0">
              Quote{' '}
              <a className="text-reset" href="mailto:dhgamache@gmail.com">
                {Date.now()}
              </a>
            </p>
          </div>
          <div className="col-auto">
            <button className="btn btn-sm btn-gray-300-20">Save quote</button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Subheader;
